<template>
	<div id="accountList">
		<div class="el-content mb12">
			<div class="kd-pacel">检索参数</div>
			<div class="al-search">
				<div class="f14">关键字：</div>
				<a-input  placeholder="昵称/手机号" v-model:value="mlState.search.key" style="width: 300px;"/>
			</div>
			<div class="al-search">
				<div class="f14">用户UID：</div>
				<a-input  placeholder="用户UID" v-model:value="mlState.search.user_id" style="width: 300px;"/>
			</div>
			<div class="al-search">
				<div class="f14">注册时间：</div>
				<a-range-picker v-model:value="mlState.search.searchTime" format="YYYY/MM/DD"/>
			</div>
			<div class="al-search">
				<div class="f14">排序：</div>
				<a-radio-group v-model:value="mlState.search.sort">
					<a-radio value="1">注册时间倒叙</a-radio>
					<a-radio value="2">注册时间正序</a-radio>
				</a-radio-group>
			</div>
			<div class="al-search">
				<div class="f14"></div>
				<a-button type="primary" @click="getMemberList(1,mlState.limit)" >查询</a-button>
			</div>
		</div>

		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table size="small" row-key="user_id" :data-source="mlState.list" :pagination="false" :columns="[
				{title:'ID',dataIndex:'user_id',sorter: (a, b) => a.user_id - b.user_id},
				{title:'昵称',dataIndex:'nickname',slots:{customRender:'nickname'}},
				{title:'手机号',dataIndex:'mobile'},
				{title:'余额',dataIndex:'bank',slots:{customRender:'bank'},sorter: (a, b) => a.gold.bank - b.gold.bank},
				{title:'积分',dataIndex:'score',slots:{customRender:'score'},sorter: (a, b) => a.score - b.score},
				{title:'优惠券(张)',dataIndex:'coupon_count',sorter: (a, b) => a.coupon_count - b.coupon_count},
				{title:'用户来源',dataIndex:'origin',slots:{customRender:'origin'}},
				{title:'注册时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]">
				<template #nickname="{record}">
					<div style="display: flex;align-items: center;">
						<a-avatar :size="32" :src="record.avatar_url" />
						<div style="margin-left: 8px;">
							<div >{{record.nickname}}</div>
							<div style="color: #999999;">真实姓名：{{record.true_name}}</div>
						</div>
					</div>
				</template>
				<template #bank="{record}">
					<a-tooltip title="点击查看余额详情">
						<router-link :to="{path:'/account/moneyRecord',query:{id:record.gold.id,type:2}}">
							<a-button type="link">{{record.gold ? record.gold.bank : 0}}</a-button>
						</router-link>
					</a-tooltip>
				</template>
				<template #score="{record}">
					<a-tooltip title="点击查看积分详情">
						<router-link :to="{path:'/account/scoreRecord',query:{id:record.user_id}}">
							<a-button type="link">{{record.score ? record.score : 0}}</a-button>
						</router-link>
					</a-tooltip>
				</template>
				<template #origin="{record}">
					<span>{{checkMemberOrigin(record.origin)}}</span>
				</template>
				<template #action="{record}">
					<a-space>
						<router-link :to="{path:'/account/account_detail',query:{id:record.user_id}}">
							<kd-button type="primary"
								title="详情"
								icon="ri-more-fill"
								v-has="{action:'/account/account_detail'}">
							</kd-button>
						</router-link>
						<com-account-recharge
							:type="1"
							:user-id="record.user_id"
							:frozen="record.gold.frozen"
							@success="getMemberList(mlState.page,mlState.limit)">
							<kd-button type="danger"
								title="充值余额"
								icon="ri-money-cny-circle-line"
								v-has="{action:'wxapp_account_recharge_balance'}">
							</kd-button>
						</com-account-recharge>
						<com-account-recharge
							:type="2"
							:user-id="record.user_id"
							:frozen="record.gold.frozen"
							@success="getMemberList(mlState.page,mlState.limit)">
							<kd-button type="purple"
								title="积分充值"
								icon="ri-database-2-line"
								v-has="{action:'wxapp_account_recharge_score'}">
							</kd-button>
						</com-account-recharge>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="mlState.page"
					:total="mlState.count"
					@showSizeChange="(p,e)=>{getMemberList(mlState.page,e)}"
					@change="(e)=>{getMemberList(e,mlState.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import comAccountRecharge from '@/components/miniapp/com-account-recharge.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
import { useMemberList,checkMemberOrigin } from "@/models/member"

export default{
	name:"AccountList",
	components:{
		comAccountRecharge
	},
	setup(){
        let { mlState ,getMemberList} = useMemberList()

        getMemberList(1,mlState.limit)

		onBeforeRouteLeave((to,from,next)=>{
            let type = to.path === '/account/account_detail' ? 1 : 2
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})

		return{
            mlState ,getMemberList,
            checkMemberOrigin
		}
	}
}
</script>

<style lang="scss">
.al-search{
    margin-bottom: 12px;
    display: flex;
    .f14{
        width: 80px;
        text-align-last:justify;
        text-align:justify;
        text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
        margin-right: 12px;
    }
}
</style>
